(function () {
  'use strict';

  class MediaRest {
    constructor($resource, env) {
      this.$resource = $resource;
      this.env = env;
      this.save = {method: 'POST', transformRequest: angular.identity, headers: {'Content-Type': undefined}};
    }

    meidas() {
      return this.$resource(`${this.env.api}/media`, null, {save: this.save});
    }

    media() {
      return this.$resource(`${this.env.api}/media/:id`, {id: '@id'}, {update: {method: 'PUT'}});
    }

  }

  /**
   * @ngdoc service
   * @name components.service:MediaRest
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('MediaRest', MediaRest);
}());
